
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import { useIonRouter } from "@ionic/vue";

@Options({
  components: {},
})
export default class AbibaroStartBlock extends Vue {
  public name = "AbibaroStartBlock";
  private ionRouter = useIonRouter();

  public onClickAbibaroCard(): void {
    this.ionRouter.navigate("/abibaro", "forward", "push");
  }

  @Prop({ default: () => false })
  public disabled!: boolean;

  @Prop({ default: () => true })
  public basicView!: boolean;
}
