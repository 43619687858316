
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({
  components: {},
})
export default class TheoryBlock extends Vue {
  @Prop({ default: "theory" })
  public theoryLink!: any;

  @Prop({ default: () => false })
  public titlePadding!: boolean;

  @Prop({ default: () => false })
  public disabled!: boolean;

  @Prop({ default: () => true })
  public basicView!: boolean;

  private onClickCard(): void {
    this.$router.push({ name: "LearnWorld" });
  }
}
